/**
 * Utilities for React apps.
 *
 * *** NEED TO BE ES-5 COMPATIBLE! ***
 */
function reactUtilDef(React, ReactDOM, History) {

    var h = React.createElement,
        render = ReactDOM.render,
        useState = React.useState,
        useRef = React.useRef,
        useEffect = React.useEffect,
        history = History.createBrowserHistory({});
        
    // ES5 (due to IExplorer) polyfill: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
    if (typeof Object.assign !== 'function') {
        Object.defineProperty(Object, "assign", {
            value: function assign(target, varArgs) {
                'use strict';
                if (target === null || target === undefined) {
                    throw new TypeError('Cannot convert undefined or null to object');
                }
                var to = Object(target);
                for (var index = 1; index < arguments.length; index++) {
                    var nextSource = arguments[index];
                    if (nextSource !== null && nextSource !== undefined) { 
                        for (var nextKey in nextSource) {
                            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                to[nextKey] = nextSource[nextKey];
                            }
                        }
                    }
                }
                return to;
            },
            writable: true,
            configurable: true
        });
    }    

    function assign(target, obj1, obj2) {
        for (var p1 in obj1) {
            if (obj1.hasOwnProperty(p1)) {
                target[p1] = obj1[p1];
            }
        }
        if (obj2) {
            for (var p2 in obj2) {
                if (obj2.hasOwnProperty(p2)) {
                    target[p2] = obj2[p2];
                }
            }
        }
        return target;
    }

    /**
     * Utility function to avoid having to use array destructuring (not supported in IE)
     */
    function useStateFn(init) {
        var s = useState(init);
        return function(v) {
            return (v === undefined ? s[0] : s[1](v));
        };
    }

    /**
     * Flash version of useStateFn. State reverts to undefined after specified time.
     */
    function useFlashStateFn(init, duration) {
        var s = useState(init), d = duration || 5000;
        return function(v) {
            if (v === undefined) return s[0];
            s[1](v);
            window.setTimeout(function() {s[1](undefined); }, d);
        };
    }

    /**
     * Flash version of useState. State reverts to undefined after specified time.
     */
    function useFlashState(init, duration) {
        var s = useState(init), d = duration || 5000;
        return [
            s[0], 
            function(v) {
                s[1](v); 
                window.setTimeout(function() {s[1](undefined); }, d);
            }
        ];
    }

    /**
     * XHR utility method (ajax requests).
     * 
     * @param {String} method Http method (GET, POST, etc).
     * @param {String} url The url to send the request to.
     * @param {String} params The params to send.
     * @param {function} success Callback function on success.
     * @param {function} error Callback function on failure.
     */
    function sendXhr(method, url, params, success, error) {
        var req = new window.XMLHttpRequest(),
            isPost = 'post' === (method||'').toLowerCase();
        if (!isPost) url = appendQueryParams(url, params);
        req.onreadystatechange = function () {
            if (req.readyState === 4) {
                if (req.status === 200) {
                    success(req.response);
                } else {
                    error(req.response, req.status);
                }
            }
        };
        req.open(method, url);
        if (isPost) {
            req.setRequestHeader('Content-type', 'application/json');
            req.send(JSON.stringify(params));
        } else {
            req.send();
        }
    }

    function appendQueryParams(url, params) {
        var first = true;
        for (var p in params) {
            if (params.hasOwnProperty(p)) {
                if (params[p] !== undefined && params[p] !== '') {
                    url += (first ? '?' : '&') + p + "=" + params[p]; // TODO: URL Encode!
                    first = false;
                }
            }
        }
        return url;
    }
        
    /**
     * Safely parses XHR response to JSON or in case of error undefined.
     * 
     * @param {JSON} response XHR response
     * @return {Object} The parsed JSON or undefined
     */
    function parseJson(response) {
        try {
            return JSON.parse(response);
        } catch (e) {
            return undefined;
        }
    }
    
    /**
     * Safely parses XHR response to JSON and extracts 'message' attribute
     * or in case of error the complete response.
     * 
     * @param {String} response XHR response
     */
    function parseMessage(response) {
        try {
            return JSON.parse(response).message;
        } catch (e) {
            return response;
        }
    }
    
    /**
     * XHR hook for sending XmlHttpRequests.
     * 
     * @returns Object: { 
     *     get: function(url, params, onSuccess, onError)
     *     post: function(url, params, onSuccess, onError)
     *     waiting: boolean
     *     done: boolean
     *     success: boolean
     *     result: JSON
     *     error: JSON
     * }
     */
    function useXhr() {
        var xhr = useStateFn({waiting: false, done: false, result: null, error: null});
        function send(method, url, params, onSuccess, onError) {
            xhr({waiting: true, done: false, result: null, error: null});
            sendXhr(method || 'GET', url, params,
                function (res) {
                    xhr({waiting: false, done: true, result: parseJson(res), error: null, status: 200});
                    onSuccess && onSuccess(parseJson(res));
                },
                function (res, status) {
                    xhr({waiting: false, done: true, result: null, error: parseMessage(res), status: status});
                    onError && onError(parseMessage(res), status);
                }
            );
        }
        return {
            get: function(url, params, onSuccess, onError) { 
                send('GET', url, params, onSuccess, onError); 
            },
            post: function(url, params, onSuccess, onError) { 
                send('POST', url, params, onSuccess, onError); 
            },
            waiting: xhr().waiting,
            done: xhr().done,
            success: xhr().done && !xhr().error,
            result: xhr().result,
            error: xhr().error,
            status: xhr().status
        };
    }

    /**
     * Input - Input component wrapping an input element in a label
     * 
     * @param {Object} props {label, value, onChange, labelProps, spanProps}
     */
    function Input(props) {
        return h('label', props.labelProps||{}, [ 
            h('span', props.spanProps||{}, props.label + ' '),
            h('input', {
                onChange: function(e) { 
                    props.onChange && props.onChange(e.target.value);
                },
                value: props.value,
                autofocus: props.autofocus
            })
        ]);
    }
    
    function copyPath(obj, path, value) {
        var rs = {};
        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                rs[p] = obj[p];
            }
        }
        rs[path] = value;
        return rs;
    }

    /**
     * XhrComponent - React/Preact component for XHR requests.
     * Each params.name will be provided as a query parameter to 
     * the action.url when submitting the request.
     * If onResult is provided it will be called with the JSON of any
     * successful results.
     * 
     * props = { 
     *     params=[{name, label, optional}],
     *     actions=[{url, method, label, waitMsg, successMsg}],
     *     onResult=function(result)
     *     spanProps={}
     * }
     */
    function XhrComponent(props) {
        var xhr = useXhr(),
            inputs = useStateFn({}),
            msgs = useStateFn({}),
            enabled = (props.params||[]).reduce(
                function(acc, p) { 
                    return acc && (p.optional || inputs()[p.name]);
                }, true);
        return h('div', {}, [
            xhr.waiting && h('h4', {}, msgs().wait || '\u231B'),
            xhr.success && h('h4', {style: {color: 'blue'}}, msgs().success), // || '\u2713'
            xhr.error && h('h4', {style: {color: 'red', outline: 'solid red', 'padding-left': '1em'}}, xhr.error),
            (props.params||[]).map(function(p) {
                return h('div', {}, 
                    h(Input, {
                        value: inputs()[p.name],
                        label: p.label,
                        placeholder: p.label + (p.optional?" (optional)":""),
                        onChange: function(v) { inputs(copyPath(inputs(), p.name, v)); },
                        spanProps: props.spanProps || {'class': 'columnNormalWide'}
                    })
                );
            }),
            props.actions.map(function(a) {
                return h('button', {
                    onClick: function() {
                        xhr[(a.method||'get').toLowerCase()](a.url, inputs(), a.onResult);
                        msgs({wait: a.waitMsg, success: a.successMsg});
                    },
                    disabled: !enabled
                }, a.label);
            })
        ]);
    };

    function locationSearchToObject(search) {
        var obj = {}, pairs = search.substring(1).split("&");
        for (var i in pairs) {
            if (pairs[i] === "") continue;
            var pair = pairs[i].split("=");
            obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
        return obj;
    }
    
    function useHistory() {
        var state = useStateFn(history.location);
        useEffect(function() {
            return history.listen(
                function(location, action) {
                    state(location);
                });
        },[]);
        history.searchToObject = function() {
            return locationSearchToObject(history.location.search);
        }
        return history;
    }

    function isNotLinkPushEvent(event) {
        return (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
                || event.defaultPrevented || event.button !== 0);
    }
    
    function Link(props) {
        var history = useHistory();
        return history.location.pathname === (props.to||'').replace(/\?.*/, '') 
            ? h('span', {}, props.children)
            : h('a', {
                onClick: function(event) {
                    if (isNotLinkPushEvent(event)) return;
                    event.preventDefault();
                    history.push(props.to);
                },
                href: props.to
            }, props.children);
    }
    
    // TODO: Replace global _messages & _locale with React context 
    // (currently not possible without using class based components for context):
    var _messages, _preferredLocale, _defaultLocale = 'sv', init = true;

    function useLocale(preferredLocale, defaultLocale) {
        if (preferredLocale) _preferredLocale = preferredLocale.replace(/[_-].*$/, '');
        if (defaultLocale) _defaultLocale = defaultLocale.replace(/[_-].*$/, '');
        return _preferredLocale || _defaultLocale;
    }
    
    function useMessages(messages, preferredLocale, defaultLocale) {
        if (init) {
            if (messages && typeof messages.messages !== 'string' && typeof messages.messages === 'object') {
                if (messages.messages) _messages = normalizeMessages(messages.messages); 
                if (messages.locale) _preferredLocale = messages.locale.replace(/[_-].*$/, '');
                if (messages.preferredLocale) _preferredLocale = messages.preferredLocale.replace(/[_-].*$/, '');
                if (messages.defaultLocale) _defaultLocale = messages.defaultLocale.replace(/[_-].*$/, '');
            } else {
                if (messages) _messages = normalizeMessages(messages);
                if (preferredLocale) _preferredLocale = preferredLocale.replace(/[_-].*$/, '');
                if (defaultLocale) _defaultLocale = defaultLocale.replace(/[_-].*$/, '');
            }
            init = false;
        }
        var state = useStateFn({m: _messages, pl: _preferredLocale, dl: _defaultLocale});
        return function(msg, localeOrReplaceParams) {
            if (typeof msg === 'string') {
                var ms = (_messages||{})[msg]||{}, m,
                    repl = typeof localeOrReplaceParams === 'object' ? localeOrReplaceParams : {},
                    locale = typeof localeOrReplaceParams === 'string' ? localeOrReplaceParams : _preferredLocale;
                m = ms[locale||_preferredLocale||_defaultLocale]||ms[_defaultLocale]||msg;
                for (var r in repl) {
                    m = m.replace(new RegExp('\\${'+r+'}', 'g'), repl[r]);
                }
                return m;
            } else if (typeof msg === 'object') {
                if (msg.messages) _messages = normalizeMessages(msg.messages); 
                if (msg.locale) _preferredLocale = msg.locale.replace(/[_-].*$/, '');
                if (msg.preferredLocale) _preferredLocale = msg.preferredLocale.replace(/[_-].*$/, '');
                if (msg.defaultLocale) _defaultLocale = msg.defaultLocale.replace(/[_-].*$/, '');
                state({m: _messages, pl: _preferredLocale, dl: _defaultLocale});
            }
            return {messages: _messages, preferredLocale: _preferredLocale, defaultLocale: _defaultLocale};
        };
    }
    
    function normalizeMessages(m) {
        if (m && m.sv && typeof m.sv !== 'string') {
            var m2 = {};
            ['sv', 'en'].forEach(function(l) {
                Object.keys(m[l]||{}).forEach(function(k) {
                    m2[k] = m2[k]||{}; m2[k][l] = m[l][k];
                });
            });
            return m2;
        }
        return m;
    }
    
    /**
     * Prevents default and stops propagation of an event.
     * @param {type} event Event to be stopped
     */
    function stopEvent(event) { 
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * Formats Unix time (milliseconds since 1970-01-01) to ISO date-time string
     * 
     * @param {type} unixTime
     * @returns {String} ISO-formatted date & time without middle T and ending hundreds and Z
     */
    function formatUnixTime(unixTime) { 
        return unixTime ? new Date(unixTime).toISOString().replace(/T|\.\d*Z$/g, ' ') : ''; 
    }

    /**
     * Returns a function that takes an event and calls the 
     * specified function with event.target.value.
     */
    function targetValue(fn) { 
        return function(event) { 
            return fn(event.target.value);
        }
    }

    return {
        h: h,
        render: render,
        useEffect: useEffect,
        useRef: useRef,
        useStateFn: useStateFn,
        useFlashStateFn: useFlashStateFn,
        useFlashState: useFlashState,
        useXhr: useXhr,
        XhrComponent: XhrComponent,
        Input: Input,
        useHistory: useHistory,
        Link: Link,
        useLocale: useLocale,
        useMessages: useMessages,
        stopEvent: stopEvent,
        formatUnixTime: formatUnixTime,
        targetValue: targetValue
    };

};

// Dependencies:
var React = React || require('react'),
    ReactDOM = ReactDOM || require('react-dom'),
    History = History || require('history');

// Define reactUtil for standalone usage without any build support:
var reactUtil = reactUtilDef(React, ReactDOM, History);

// Export componentUtils for use with build systems, e.g. Webpack:
if (module) module.exports = reactUtil
