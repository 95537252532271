import React, {useState, useEffect} from 'react';

/**
 * Components for configuring the filters for the enrollments in the EnrollmentTable.
 */
export default function EnrollmentFilter({i18n, context, reregistered = [], roles=[], enrollmentStates=[], filterState: [filters, setFilters], handleSearch}) {
    const {programs, sections, groupCategories, groups} = context;

    const [filterReReg, setFilterReReg] = useState(reregistered.map(r => ({reregistered: false})));
    const [filterReRegEnabled, setFilterReRegEnabled] = useState(false);

    const [filterFirstTimeReg, setFilterFirstTimeReg] = useState(reregistered.map(r => ({reregistered: true})));
    const [filterFirstTimeEnabled, setfilterFirstTimeEnabled] = useState(false);

    const [filterRolesEnabled, setFilterRolesEnabled] = useState(false);
    const [filterRoles, setFilterRoles] = useState(roles.map(r => ({role: r, enabled:false})));

    const [filterEnrollmentStatesEnabled, setFilterEnrollmentStatesEnabled] = useState(false);
    const [filterEnrollmentStates, setFilterEnrollmentStates] = useState(
        enrollmentStates.map(s => ({enrollmentState: s, enabled:false})));

    const [filterProgramsEnabled, setFilterProgramsEnabled] = useState(false);
    const [filterPrograms, setFilterPrograms] = useState(programs.map(s=>Object.assign({}, s, {enabled:false})));

    const [filterSectionsEnabled, setFilterSectionsEnabled] = useState(false);
    const [filterSections, setFilterSections] = useState(sections.map(s=>Object.assign({}, s, {enabled:false})));

    const [filterGroupsEnabled, setFilterGroupsEnabled] = useState(false);
    const [filterGroupCategory, setFilterGroupCategory] = useState(
        groupCategories.map(g => ({gc: g, enabled:false}))
    );
    /* Keep track if groupcategory is open or closed */
    const [openGroupCategory, setOpenGroupCategory] = useState(false);
    const [filterGroups, setFilterGroups] = useState();

    useEffect(() => setFilters({
        reregistered: filterReRegEnabled && filterReReg && filterReReg.filter(r => r.reregistered) ||
            filterFirstTimeEnabled && filterFirstTimeReg && filterFirstTimeReg.filter(r => ! r.reregistered),
        roles: filterRolesEnabled && filterRoles && filterRoles.filter(r => r.enabled),
        enrollmentStates: filterEnrollmentStatesEnabled && filterEnrollmentStates && filterEnrollmentStates.filter(s => s.enabled),
        sections: filterSectionsEnabled && filterSections && filterSections.filter(s => s.enabled),
        programs: filterProgramsEnabled && filterPrograms && filterPrograms.filter(p => p.enabled),
        //groups: filterGroupsEnabled && filterGroups && filterGroups.filter(g => g.enabled),
        groups: filterGroupsEnabled && filterSelectedGroups && filterSelectedGroups.filter(g => g.enabled),
        filterSelectedGroups: []
    }), [filterRolesEnabled, filterRoles, filterSectionsEnabled, filterSections, filterPrograms,
         filterGroupsEnabled, filterGroups, filterEnrollmentStatesEnabled, filterEnrollmentStates, filterReReg, filterFirstTimeReg]);

    const [useGroupFilter, setUseGroupFilter] = useState(false);

    const resetReregistered = () => {
        setfilterFirstTimeEnabled(false);
        setFilterFirstTimeReg(filterFirstTimeReg.map(fs => Object.assign({}, fs, {reregistered: false})));
        setFilterReRegEnabled(false);
        setFilterReReg(filterReReg.map(fs => Object.assign({}, fs, {reregistered: false})));
    }

    const showReregistered = (s) => {
        setFilterReRegEnabled(true);
        let filter = filterReReg.map(
            fs => (fs.reregistered !== s.reregistered) ? fs : Object.assign({}, s, {reregistered: ! s.enabled}));
        setFilterReReg(filter);
    }

    const showFirstTimeRegistered = (s) => {
        setfilterFirstTimeEnabled(true);
        let filter = filterFirstTimeReg.map(
            fs => (fs.reregistered !== s.reregistered) ? fs : Object.assign({}, s, {reregistered: false}));
        setFilterFirstTimeReg(filter);
    }
    const [isFirstTimeRegChecked, setFirstTimeRegChecked] = useState(false);
    const onClickFirstReg = (e, s) => {
        let checked = e.target.checked;
        setFirstTimeRegChecked(current => ! current);
        /* If s is null, reregistration checkbox is disabled */
        if (s === null) {
            return;
        }
        if (checked && isReRegChecked) {
            return resetReregistered();
        }
        if (checked) {
            s.reregistered = true;
        }
        if (! checked) {
            setfilterFirstTimeEnabled(false);
            setFilterFirstTimeReg(filterFirstTimeReg.map(fs => Object.assign({}, fs, {reregistered: false})));
            if (isReRegChecked) {
                showReregistered(s);
            }
        } else {
            showFirstTimeRegistered(s)
        }
    }
    const [isReRegChecked, setReRegChecked] = useState(false);
    const onReregClick = (e, s) => {
        let checked = e.target.checked;
        setReRegChecked(current => ! current);
        if (checked && isFirstTimeRegChecked) {
            return resetReregistered();
        }
        if (checked) {
            s.reregistered = true;
        }
        if (! checked) {
            setFilterReRegEnabled(false);
            setFilterReReg(filterReReg.map(fs => Object.assign({}, fs, {reregistered: false})));
            if (isFirstTimeRegChecked) {
                showFirstTimeRegistered(s);
            }
        } else {
            showReregistered(s);
        }
    }

    /** Enrollment state */
    const onStatusSelectClick = (e, s, nrEnabled) => {
        if (nrEnabled <= 1 && s.enabled) {
            setFilterEnrollmentStatesEnabled(false);
            setFilterEnrollmentStates(filterEnrollmentStates.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            setFilterEnrollmentStatesEnabled(true);
            setFilterEnrollmentStates(filterEnrollmentStates.map(
                fs => (fs.enrollmentState !== s.enrollmentState) ? fs : Object.assign({}, s, {enabled: !s.enabled})));
        }
    }
    const onRoleSelectClick = (e, role, nrEnabled) => {
        let newRoleValue = ! role.enabled;
        if (nrEnabled <= 1 && (role.enabled === null || role.enabled)) {
            setFilterRolesEnabled(false);
            setFilterRoles(filterRoles.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            setFilterRolesEnabled(true);
            setFilterRoles(filterRoles.map(r => (r.role !== role.role) ? r : Object.assign({}, role, {enabled: newRoleValue})));
        }
    };
    const onRemoveReregisteredClick = (e) => {
        setFilterReRegEnabled(false);
        setFilterReReg(filterReReg.map(fs => Object.assign({}, fs, {reregistered: false})));
        setReRegChecked(false);
    };
    const onRemoveFirstTimeRegisteredClick = () => {
        setfilterFirstTimeEnabled(false);
        setFilterFirstTimeReg(filterFirstTimeReg.map(fs => Object.assign({}, fs, {reregistered: true})));
        setFirstTimeRegChecked(false);
    }
    const onRemoveRoleFilterClick = (e, role, noApplied, nrEnabled) => {
        if (nrEnabled <= 1) {
            setFilterRolesEnabled(false);
        }
        setFilterRoles(
            filterRoles.map(fr => (fr.role !== role.role) ? fr : Object.assign({}, role, {enabled: false})));
    };
    const onSectionSelect = (e, s, nrEnabled) => {
        if (nrEnabled <= 1 && s.enabled) {
            setFilterSectionsEnabled(false);
            setFilterSections(filterSections.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            setFilterSectionsEnabled(true);
            setFilterSections(filterSections.map(fs => (fs.id !== s.id) ? fs : Object.assign({}, s, {enabled: !s.enabled})));
        }
    };
    const onProgramSelect = (e, s, nrEnabled) => {
        if (nrEnabled <= 1 && s.enabled) {
            setFilterProgramsEnabled(false);
            setFilterPrograms(filterPrograms.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            setFilterProgramsEnabled(true);
            setFilterPrograms(filterPrograms.map(fs => (fs.id !== s.id) ? fs : Object.assign({}, s, {enabled: !s.enabled})));
        }
    };

    const onRemoveProgramFilterClick = (e, program, nrEnabled) => {
        if (nrEnabled <= 1) {
            setFilterProgramsEnabled(false);
            setFilterPrograms(filterPrograms.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            setFilterProgramsEnabled(true);
            setFilterPrograms(filterPrograms.map(fs => (fs.id !== program.id) ? fs : Object.assign({}, program, {enabled: false})));
        }
    }

    const onRemoveSectionFilterClick = (e, section, nrEnabled) => {
        if (nrEnabled <= 1) {
            setFilterSectionsEnabled(false);
            setFilterSections(filterSections.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            setFilterSectionsEnabled(true);
            setFilterSections(filterSections.map(fs => (fs.id !== section.id) ? fs : Object.assign({}, section, {enabled: false})));
        }
    }
    const onRemoveStatusFilterClick = (e, status, noApplied, nrEnabled) => {
        if (nrEnabled <= 1) {
            setFilterEnrollmentStatesEnabled(false)
        }
        setFilterEnrollmentStates(filterEnrollmentStates.map(
            fs => (fs.enrollmentState !== status.enrollmentState) ? fs : Object.assign({}, status, {enabled: false})));
    }

    const onRemoveGroupFilterClick = (e, group, nrEnabled) => {
        deleteGroupFilter(group);
        if (nrEnabled <= 1) {
            setFilterGroups(
                filterGroups.map(fg => Object.assign({}, fg, {enabled: false})));
            setFilterGroupsEnabled(false);
            setOpenGroupCategory(false);
        } else {
            setFilterGroupsEnabled(true);
            setFilterGroups(
                filterGroups.map(fg => (fg.id !== group.id) ? fg : Object.assign({}, group, {enabled: false})));
        }
    }

    const [filterSelectedGroups, setFilterSelectedGroups] = useState([]);

    const addGroupFilter = group => {
        if (group == null || group === 'undefined') {
            return;
        }
        group.enabled = true;
        if (! filterSelectedGroups.some(e => e.id === group.id)) {
            filterSelectedGroups.push(group);
            setFilterSelectedGroups(filterSelectedGroups);
        }
    }
    const deleteGroupFilter = group => {
        group.enabled = false;
        let index = filterSelectedGroups.map(g => g.id).indexOf(group.id);
        filterSelectedGroups.splice(index, 1)
        setFilterSelectedGroups(filterSelectedGroups);
    }

    const onGroupSelect = (e, group, nrEnabled) => {
        let isGroupEnabled = group.enabled;
        isGroupEnabled ? deleteGroupFilter(group) : addGroupFilter(group);
        if (nrEnabled <= 1 && isGroupEnabled) {
            setFilterGroups(
                filterGroups.map(fg => (fg.id !== group.id) ? fg : Object.assign({}, group, {enabled: false})));
            if (filterSelectedGroups.length === 0) {
                /* If no group selected - disable the group filter */
                setFilterGroupsEnabled(false);
                //setOpenGroupCategory(true);
            }
        } else {
            setFilterGroupsEnabled(true);
            setFilterGroups(
                filterGroups.map(fg => (fg.id !== group.id) ? fg : Object.assign({}, group, {enabled: ! isGroupEnabled})));
        }
    };

    const onGroupCategorySelect = (gc) => e => {
        e.preventDefault();
        if (filterGroupCategory.id === gc.id) {
            setOpenGroupCategory(openGroupCategory => ! openGroupCategory);
            //if (! openGroupCategory) {
            if (openGroupCategory) {
                //setOpenGroupCategory(true);
                setFilterGroupsEnabled(true);
            }
            //if (filterSelectedGroups.length === 0) {
                /* No groups filter when group filter is closed */
                //resetGroupFilter();
            //}
        } else {
            setOpenGroupCategory(true);
            setFilterGroupsEnabled(true);
            setFilterGroupCategory(gc);
            setFilterGroups(gc && groups.filter(g => g.group_category_id === gc.id)
                .map(g => Object.assign({}, g, {enabled: filterSelectedGroups.some(e => e.id === g.id)})));
        }
    };

    const toggleGroupFilter = e => {
        let nrEnabled = 0;
        if (filterSelectedGroups != null) {
            nrEnabled = filterSelectedGroups.filter((obj) => obj.enabled).length;
        }
        if (nrEnabled === 0 && useGroupFilter) {
            /* No groups filter when group filter is closed */
            resetGroupFilter();
        }
        setUseGroupFilter(current => ! current);
    };
    const resetGroupFilter = e => {
        setOpenGroupCategory(false);
        setFilterGroupsEnabled(false);
        if (filterGroups != null) {
            setFilterGroups(filterGroups.map(g => Object.assign({}, g, {enabled: false})));
        }
        setFilterSelectedGroups([])
    }
    const onSectionFilterClick = e => {
        setFilterSectionsEnabled(filterSections.filter((item) => item.enabled).length > 0);
    }
    const onProgramFilterClick = e => {
        setFilterProgramsEnabled(filterPrograms.filter((item) => item.enabled).length > 0);
    }
    const resetAllFilter = e => {
        setFilterRoles(
            filterRoles.map(r => Object.assign({}, r, {enabled: false})));
        setFilterRolesEnabled(false)
        setFilterEnrollmentStates(filterEnrollmentStates.map(
            s =>  Object.assign({}, s, {enabled: false})));
        setFilterEnrollmentStatesEnabled(false)
        setFilterSections(filterSections.map(fs => Object.assign({}, fs, {enabled: false})))
        setFilterSectionsEnabled(false);
        setFilterPrograms(filterPrograms.map(fs => Object.assign({}, fs, {enabled: false})))
        setFilterProgramsEnabled(false);
        setOpenGroupCategory(false);
        if (filterGroups != null) {
            setFilterGroups(filterGroups.map(g => Object.assign({}, g, {enabled: false})));
        }
        setFilterGroupsEnabled(false);
        setFilterSelectedGroups([])
        onRemoveReregisteredClick();
        onRemoveFirstTimeRegisteredClick();
    }

    const noFiltersPresent = (roles, enrollmentStates, programs, sections, selectedGroups) => {
        let noRolesEnabled = roles.filter(r => r.enabled == null || r.enabled).length === 0;
        let stateIsUsed = enrollmentStates.filter(s => s.enabled).length > 0;
        let noSectionsEnabled = sections.filter(s => s.enabled).length === 0;
        let noProgramsEnabled = programs.filter(p => p.enabled).length === 0;
        return selectedGroups.length == 0 && noRolesEnabled && ! stateIsUsed && noSectionsEnabled && noProgramsEnabled &&
            ! isReRegChecked && ! isFirstTimeRegChecked;
    }
    const [showModal, setShowModal] = useState(false);
    const Modal = ({ i18n, setShowModal, show}) => {
        const showHideClassName = show ? "modal display-block" : "modal display-none";
        return (
            <div className={showHideClassName}>
                <section className="modal-main">
                    <div className="form-group">
                        <h3>{i18n('show.help.header-guide')}</h3>
                        <p>{i18n('show.help.all-info')} <a href={i18n('show.help.all-info-link-url')} target="_blank">{i18n('show.help.all-info-link')}</a></p>
                        <h3>{i18n('show.help.header-lexicon')}</h3>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <td className="bold-font">{i18n('show.help.colheader1')}</td><td className="bold-font">{i18n('show.help.colheader2')}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colSpan="2">{i18n('show.help.row1')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row2a')}</td><td>{i18n('show.help.row2b')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row3a')}</td><td>{i18n('show.help.row3b')}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">{i18n('show.help.row4')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row5a')}</td><td>{i18n('show.help.row5b')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row6a')}</td><td>{i18n('show.help.row6b')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row7a')}</td><td>{i18n('show.help.row7b')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row8a')}</td><td>{i18n('show.help.row8b')}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">{i18n('show.help.row9')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row10a')}</td><td>{i18n('show.help.row10b')}</td>
                                </tr>
                                <tr>
                                    <td>{i18n('show.help.row11a')}</td><td>{i18n('show.help.row11b')}</td>
                                </tr>
                                </tbody>
                            </table>
                            <p className="nav-link"><a href={i18n('show.help.link-roles')} target="_blank">{i18n('show.help.read-more')}</a></p>
                        </div>
                        <div className="float-end">
                            <button className="button-outline" onClick={e => setShowModal(false) }>OK</button>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <div className="no-print enrollment-filter mt-3">
            <div className="mb-3">
                <div className="row no-print">
                    <div className="col-md-12">
                        <div className="float-end mb-2">
                            <button className="no-print button-text button-icon icon-help" onClick={e => setShowModal(true) }>{i18n('show.help')}</button>
                        </div>
                        <Modal i18n={i18n} show={showModal} setShowModal={setShowModal}>
                            <p>Modal</p>
                        </Modal>
                        <h3 className="toggle-header mt-4">
                            <button className="toggle-button button-text button-icon m-0" data-bs-toggle="collapse"
                                    data-bs-target="#toggle-1" aria-controls="toggle-1" aria-expanded="false">{i18n('filter.in.student.list')}
                            </button>
                        </h3>

                        <div id="toggle-1" className="toggle-content collapse">
                            <div className="card">
                                <div className="card-body">
                                    <div className="anchor" id="search-list"></div>
                                    <div className="search-list">
                                        {/*
                                        <form className="search-form flex-sm-column">
                                            <label htmlFor="mySearchFilter" className="visually-hidden">Filtrera tabellen</label>
                                            <input className="search-form-input" type="search" id="mySearchFilter" autoComplete="off" onChange={handleSearch} name="q"/>
                                        </form>
                                        */}
                                        <div className="button-group search-filters" id="accordion">
                                            <h4 className="visually-hidden">{i18n('filtering')}</h4>
                                            {! noFiltersPresent(filterRoles, filterEnrollmentStates, filterPrograms, filterSections, filterSelectedGroups, filterFirstTimeReg) &&
                                                <div className="search-filters-selected">
                                                <h4 className="visually-hidden">{i18n('you.have.filtered.on')}</h4>
                                                {/* The role filter */}
                                                <div className="col-10">
                                                    <FilterSelectedReRegistered i18n={i18n} itemsReReg={filterReReg} onClickReReg={onRemoveReregisteredClick}
                                                                                itemsFirstTime={filterFirstTimeReg} onClickFirstTime={onRemoveFirstTimeRegisteredClick}
                                                                                reRegChecked={isReRegChecked}
                                                                                firstTimeChecked={isFirstTimeRegChecked}></FilterSelectedReRegistered>
                                                    <FilterSelectedRoles items={filterRoles} infoText={i18n('filter.click.to.remove')}
                                                                         label={r => i18n(`table.value.role.${r.role}`)}
                                                                         onClick={onRemoveRoleFilterClick}></FilterSelectedRoles>
                                                    {/* The status filter */}
                                                    <FilterSelectedState items={filterEnrollmentStates} infoText={i18n('filter.click.to.remove')}
                                                                         label={s => i18n(`table.value.enrollmentState.${s.enrollmentState}`)} onClick={onRemoveStatusFilterClick}></FilterSelectedState>
                                                    {/* The programs filter */}
                                                    <FilterSelectedItems items={filterPrograms} infoText={i18n('filter.click.to.remove')}
                                                                         onClick={onRemoveProgramFilterClick} itemType="program"></FilterSelectedItems>
                                                    {/* The sections filter */}
                                                    <FilterSelectedItems items={filterSections} infoText={i18n('filter.click.to.remove')}
                                                                         onClick={onRemoveSectionFilterClick} itemType="section"></FilterSelectedItems>
                                                    {/* The group filter */}
                                                    <FilterSelectedItems items={filterSelectedGroups} infoText={i18n('filter.click.to.remove')}
                                                                             onClick={onRemoveGroupFilterClick} itemType="group"></FilterSelectedItems>
                                                </div>
                                                <ResetFilter i18n={i18n} onClick={resetAllFilter} selectedGroups={filterSelectedGroups}
                                                             roles={filterRoles} enrollmentStates={filterEnrollmentStates} programs={filterPrograms} sections={filterSections}
                                                             rereg={filterReReg} noFiltersPresent={noFiltersPresent}></ResetFilter>
                                            </div> }
                                            <div className="filter-container">
                                                {/* The registering info filter, composed of role and enrollmentState */}
                                                <button className="button-filter button-icon icon-filter" data-bs-toggle="collapse"
                                                        data-bs-target="#filter-1" aria-controls="filter-1" aria-expanded="false"><span
                                                    className="visually-hidden">{i18n('filter.on')} </span>{i18n('filter.reginfo')}
                                                </button>
                                              <FilterRegisteringInfo i18n={i18n} index={1}
                                                                   roleLabel={r => i18n(`table.value.role.${r.role}`)}
                                                                   stateLabel={r => i18n(`table.value.enrollmentState.${r.enrollmentState}`)}
                                                                   roleItems={filterRoles} stateItems={filterEnrollmentStates} reRegItems={filterReReg}
                                                                   roleSetter={setFilterRoles} stateSetter={setFilterEnrollmentStates} reRegSetter={setFilterReReg}
                                                                   roleOnClick={onRoleSelectClick} stateOnClick={onStatusSelectClick} reregOnClick={onReregClick}
                                                                   isReRegChecked={isReRegChecked} isFirstTimeChecked={isFirstTimeRegChecked}
                                                                     onClickFirstReg={onClickFirstReg}></FilterRegisteringInfo>
                                                {/* The program filter */}
                                                <button className="button-filter button-icon icon-filter" data-bs-toggle="collapse"
                                                        data-bs-target="#filter-2" aria-controls="filter-2" aria-expanded="false" onClick={onProgramFilterClick}><span
                                                    className="visually-hidden">{i18n('filter.on')} </span>{i18n('filter.on.program')}
                                                </button>
                                                <div id="filter-2" className="filter-content card collapse" data-bs-parent="#accordion">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <FilterSelect i18n={i18n} enabled={true} legend={i18n('filter.legend.programs')}
                                                                          items={filterPrograms} setter={setFilterPrograms} onClick={onProgramSelect} itemType="program"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* The section filter */}
                                                <button className="button-filter button-icon icon-filter" data-bs-toggle="collapse"
                                                        data-bs-target="#filter-3" aria-controls="filter-3" aria-expanded="false" onClick={onSectionFilterClick}><span
                                                    className="visually-hidden">{i18n('filter.on')} </span>{i18n('filter.on.section')}
                                                </button>
                                                <div id="filter-3" className="filter-content card collapse" data-bs-parent="#accordion">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <FilterSelect i18n={i18n} enabled={true} legend={i18n('filter.legend.sections')}
                                                                          items={filterSections} setter={setFilterSections} onClick={onSectionSelect} itemType="section"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* The group filter */}
                                                <button className="button-filter button-icon icon-filter" data-bs-toggle="collapse"
                                                        data-bs-target="#filter-4" aria-controls="filter-4" aria-expanded="false" onClick={toggleGroupFilter}><span
                                                    className="visually-hidden">{i18n('filter.on')} </span>{i18n('filter.on.groups')}
                                                </button>
                                                <div id="filter-4" className="filter-content card collapse" data-bs-parent="#accordion">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <FilterSelectGroupCategory i18n={i18n} enabled={true} legend={i18n('filter.legend.groupCategories')}
                                                                              items={groupCategories} groups={filterGroups} onCategoryClick={onGroupCategorySelect}
                                                                              groupCategory={filterGroupCategory} filterGroupsEnabled={filterGroupsEnabled}
                                                                              filterGroups={filterGroups} setFilterGroups={setFilterGroups} onGroupSelect={onGroupSelect}
                                                                              addGroupFilter={addGroupFilter} deleteGroupFilter={deleteGroupFilter}
                                                                              openGroup={openGroupCategory}
                                                                              checked={gc => gc.id === (filterGroupCategory||{}).id} type="button" setFilterGroupsEnabled={setFilterGroupsEnabled}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FilterSelect({i18n, enabled, legend, items = [], label = i => i.name, type='checkbox',
                       name = i => i.id, checked = i => i.enabled, setter, onClick, className, itemType}) {
    const nrEnabled = items.filter((item) => item.enabled).length;
    const allEnabled = nrEnabled == items.length;
    const selectAll = e => {
        setter(items.map(i => Object.assign({}, i, {enabled: !allEnabled})));
    }
    return (
        <div className={className}>
            {enabled &&
             <fieldset>
                 {type === 'checkbox' && setter && items.length > 1 &&
                  <div className="ms-3 mt-1 mb-3">
                      <label>
                          <input type={type}
                                 name={'select.all'}
                                 checked={allEnabled}
                                 onChange={selectAll}>
                          </input>
                          &nbsp; {i18n('filter.select.all')}
                      </label>
                  </div>
                 }
                 <ul className="list-unstyled ms-3">
                 {items.map((item, index) =>
                     <li key={index} className={item.enabled ? 'nobullet' : ''}>
                         {/*
                         <button id={'item-' + itemType + '-' + item.id} className={item.enabled ? 'button-text button-icon icon-clear' : 'button-text button-icon icon-add'} onClick={(e) => onClick(e, item, nrEnabled)}>{label(item)}<span
                             className="visually-hidden">{i18n('click.to.choose.filter')} {index + 1}</span></button>
                             */}
                         <label>
                             <input type="checkbox"
                                    id={'item-' + itemType + '-' + item.id}
                                    checked={checked(item)}
                                    onChange={(e) => onClick(e, item, nrEnabled)}>
                             </input>
                             &nbsp; {label(item)}
                         </label>
                     </li>
                 )}
                 </ul>
             </fieldset>
            }
        </div>
    );
}

function FilterSelectGroup({i18n, enabled, items = [], label = i => i.name, type='checkbox',
                          name = i => i.id, checked = i => i.enabled, setter, onClick,
                               addGroupFilter, deleteGroupFilter, openGroup}) {
    let nrEnabled = items.filter((item) => item.enabled).length;
    const allEnabled = nrEnabled === items.length
    const selectAll = e => {
        items.forEach(item => allEnabled ?  deleteGroupFilter(item) : addGroupFilter(item));
        setter(items.map(i => Object.assign({}, i, {enabled: ! allEnabled})));
    }

    return (
        <div className="ms-4">
            {enabled &&
                <fieldset>
                    {type === 'checkbox' && setter && items.length > 1 &&
                        <div className="mb-3">
                            <label>
                                <input type={type}
                                       name={'select.all'}
                                       checked={openGroup && items.filter((item) => item.enabled).length === items.length}
                                       id="check-all-groups"
                                       onChange={selectAll}>
                                </input>
                                &nbsp; {i18n('filter.select.all')}
                            </label>
                        </div>
                    }
                    <div className="list-columns">
                        <ul className="list-unstyled">
                        {items.map((item, index) =>
                            <div key={index}>
                                <li key={index} className={item.enabled ? 'nobullet' : ''}>
                                    {/*
                                    <button id={'item-group-' + item.id} className={item.enabled ? 'button-text button-icon icon-clear search-filter-selected' : 'button-text button-icon icon-add search-filter-selected'}
                                            onClick={(e) => onClick(e, item, nrEnabled)}>{label(item)}<span
                                        className="visually-hidden">{i18n('click.to.choose.filter')} {index + 1}</span></button>
                                        */}
                                    <label>
                                        <input type="checkbox"
                                               id={'item-group-' + item.id}
                                               checked={checked(item)}
                                               onChange={(e) => onClick(e, item, nrEnabled)}>
                                        </input>
                                        &nbsp; {label(item)}
                                    </label>
                                </li>
                            </div>
                        )}
                        </ul>
                    </div>
                </fieldset>
            }
        </div>
    );
}

function FilterSelectGroupCategory({i18n, enabled, legend, items = [], label = i => i.name, type='button',
                                groupCategory, filterGroupsEnabled, filterGroups, setFilterGroups, onGroupSelect,
                                addGroupFilter, deleteGroupFilter, openGroup,
                               name = i => i.id, onCategoryClick}) {
    return (
        <div>
            {enabled && <div className="toggle-filter">
                {items.map((item, index) =>
                        <div key={index} className="border-top">
                            <button
                                   value={name(item)}
                                   onClick={onCategoryClick(item, groupCategory.id)}
                                   aria-expanded={openGroup && item.id === groupCategory.id ? "true" : "false"}
                                   className={openGroup && item.id === groupCategory.id ?
                                       "toggle-button button-text button-icon" : "toggle-button button-text button-icon collapsed"} >{label(item)}
                            </button>
                            {openGroup && item.id === groupCategory.id &&
                                <div className="col-md-12">
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                        <FilterSelectGroup enabled={true}
                                                           i18n={i18n} items={filterGroups} setter={setFilterGroups}
                                                           onClick={onGroupSelect}
                                                           addGroupFilter={addGroupFilter}
                                                           deleteGroupFilter={deleteGroupFilter}
                                                           openGroup={openGroup}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    )}
            </div>
            }
        </div>

    );
}

function FilterSelectRole({i18n, label = i => i.name, items = [], onClick, checked = i => i.enabled == null || i.enabled}) {
    const nrEnabled = items.filter(item => item.enabled == null || item.enabled).length;
    return (
            items.map((item, index) =>
                    <li key={index} className={item.enabled || item.enabled == null ? 'nobullet' : ''} >
                        {/*
                        <button id={'role-' + index} className={item.enabled || item.enabled == null ? 'button-text button-icon icon-clear search-filter-selected' : 'button-text button-icon icon-add search-filter-selected'}
                                onClick={(e) => onClick(e, item, nrEnabled)}>{label(item)}<span
                            className="visually-hidden">{i18n('click.to.choose.filter')} {index + 1}</span></button>
                            */}

                        <label>
                            <input type="checkbox"
                                   id={'role-' + index}
                                   checked={checked(item)}
                                   onChange={(e) => onClick(e, item, nrEnabled)}>
                            </input>
                            &nbsp; {label(item)}
                        </label>
                    </li>
            )
    )
}

/**
 * Return a checkbox for reregistrered (omregistrerade) and a checkbox for first time registered "Förstagångsregistrerade"
 * If the items map is empty then there are no reregistrered (omregistrerade) and the reregistered checkbox is disabled
 * "Förstagångsregistrerade" = everyone that's not "Omregistrerad"
 */
function FilterSelectReReg({i18n, items = [], onClickReReg, onClickFirstReg, checked = i => i.enabled, isReRegChecked, isFirstTimeChecked}) {
    const index = 0;
    return (
        items.length === 0 ?
            <div key={index + 4}>
                <li key={index + 22} className='nobullet'>
                    <label>
                        <input type="checkbox"
                               id={'firstregcheckbox'}
                               checked={isFirstTimeChecked}
                               onChange={(e) => onClickFirstReg(e, null)}>
                        </input>
                        &nbsp; {i18n('table.value.enrollmentState.firsttimeregistered')}
                    </label>
                </li>
                <li key={index + 21} className='nobullet'>
                    <label>
                        <input type="checkbox" disabled>
                        </input>
                        &nbsp; {i18n('table.value.enrollmentState.reregistered')}
                    </label>
                </li>
            </div>
            :
            <div key={index + 3}>
                <li key={index + 2} className='nobullet'>
                    <label>
                        <input type="checkbox"
                               checked={isFirstTimeChecked}
                               id={'firstregcheckbox'}
                               onChange={(e) => onClickFirstReg(e, items[0])}>
                        </input>
                        &nbsp; {i18n('table.value.enrollmentState.firsttimeregistered')}
                    </label>
                </li>
                <li key={index + 1} className={items[0].reregistered ? 'nobullet' : ''}>
                    {/*
                    <button id={'rereg-' + index}
                            className={item.reregistered ? 'button-text button-icon icon-clear search-filter-selected' : 'button-text button-icon icon-add search-filter-selected'}
                            onClick={(e) => onClick(e, item)}>{i18n('table.value.enrollmentState.reregistered')}<span
                        className="visually-hidden">{i18n('click.to.choose.filter')} {index + 1}</span></button>
                               checked={items[0].reregistered ? true : false}
                        */}
                    <label>
                        <input type="checkbox"
                               id={'reregcheckbox'}
                               checked={isReRegChecked}
                               onChange={(e) => onClickReReg(e, items[0])}>
                        </input>
                        &nbsp; {i18n('table.value.enrollmentState.reregistered')}
                    </label>
                </li>
            </div>
        //)
    )
}

function FilterSelectState({i18n, label = i => i.name, items = [], onClick, checked = i => i.enabled}) {
    const nrEnabled = items.filter((item) => item.enabled).length;
    return (
            items.map((item, index) =>
                <li key={index} className={item.enabled ? 'nobullet' : ''}>
                    {/*
                    <button id={'state-' + index}
                            className={item.enabled ? 'button-text button-icon icon-clear search-filter-selected' : 'button-text button-icon icon-add search-filter-selected'}
                            onClick={(e) => onClick(e, item, nrEnabled)}>{label(item)}<span
                        className="visually-hidden">{i18n('click.to.choose.filter')} {index + 1}</span></button>
                        */}
                    <label>
                        <input type="checkbox"
                               id={'state-' + index}
                               checked={checked(item)}
                               onChange={(e) => onClick(e, item, nrEnabled)}>
                        </input>
                        &nbsp; {label(item)}
                    </label>
                </li>
            )
    )
}

/**
 * Show the missing state completed - but disable it
 */
function MissingState({i18n, items = []}) {
    const missingFinished = items.length == 1 && items[0].enrollmentState === 'active'
    return (
        missingFinished &&
        <li> <label>
            <input disabled={true} type="checkbox" id={'state-missing'}>
            </input>
            &nbsp; {i18n('table.value.enrollmentState.completed')}
        </label></li>
    )
}

function FilterSelectedReRegistered({i18n, itemsReReg = [], onClickReReg, itemsFirstTime, onClickFirstTime, reRegChecked, firstTimeChecked}) {
    return (
        (reRegChecked || firstTimeChecked) &&
        <span>
            {reRegChecked &&
                <button id={'selected-rereg'} onClick={(e) => onClickReReg(e, itemsReReg[0])} className="button-text button-icon icon-clear search-filter-selected">{i18n('table.value.enrollmentState.reregistered')} <span
                    className="visually-hidden">{i18n('filter.click.to.remove')}</span></button>
            }
            {firstTimeChecked &&
                <button id={'selected-firsttimereg'} onClick={(e) => onClickFirstTime(e, itemsFirstTime[0])} className="button-text button-icon icon-clear search-filter-selected">{i18n('table.value.enrollmentState.firsttimeregistered')} <span
                    className="visually-hidden">{i18n('filter.click.to.remove')}</span></button>
            }
        </span>
    )
}
function FilterSelectedRoles({items = [], infoText, label = i => i.name, onClick}) {
    let allEnabled = items.reduce((acc, i) => acc && i.enabled, true);
    /* Special case for role */
    if (allEnabled == null) {
        allEnabled = true;
    }
    const nrEnabled = items.filter((item) => (item.enabled == null || item.enabled)).length;
    return (
        <span>
            {items.filter(r => (r.enabled == null || r.enabled)).map((item, index) =>
                <button id={'selected-role-' + index} key={index} onClick={(e) => onClick(e, item, allEnabled, nrEnabled)} className="button-text button-icon icon-clear search-filter-selected">{label(item)} <span
                    className="visually-hidden">{infoText + (index + 1)}</span></button>
            )}
        </span>
    )
}
function FilterSelectedState({items = [], infoText, label = i => i.name, onClick}) {
    let allEnabled = items.reduce((acc, i) => acc && i.enabled, false);
    let nrEnabled = items.filter(obj => obj.enabled).length;
    return (
        <span className="">
            {items.filter(r => (r.enabled)).map((item, index) =>
                <button id={'selected-state-' + index} key={index} onClick={(e) => onClick(e, item, allEnabled, nrEnabled)} className="button-text button-icon icon-clear search-filter-selected">{label(item)} <span
                    className="visually-hidden">{infoText + (index + 1)}</span></button>
            )}
        </span>
    )
}

function FilterSelectedItems({items = [], infoText, onClick, itemType}) {
    let nrEnabled = items.filter((obj) => obj.enabled).length;
    return (
        <span className="">
            {items.filter(r => (r.enabled)).map((item, index) =>
                <button id={'selected-item-' + itemType + '-' + item.id} name="sectionFilter" key={index} onClick={(e) => onClick(e, item, nrEnabled)} className="button-text button-icon icon-clear search-filter-selected">{item.name} <span
                    className="visually-hidden">{infoText + (index + 1)}</span>
                </button>
            )}
        </span>
    )
}

function ResetFilter({i18n, onClick, selectedGroups, roles, enrollmentStates, programs, sections, rereg, noFiltersPresent}) {
    let className = "button-text button-icon icon-clear search-filter-selected float-end";
    if (noFiltersPresent(roles, enrollmentStates, programs, sections, rereg, selectedGroups)) {
        className = className + " display-none"
    }
    return (
        <div className="col-2">
            <button onClick={onClick} name="sectionFilter" className={className}>{i18n('reset.all.filter')}
                <span className="visually-hidden">{i18n('reset.all.filter')}</span>
            </button>
        </div>
    )
}

function FilterRegisteringInfo({i18n, index, roleLabel = i => i.name, stateLabel = i => i.name, roleItems = [], stateItems = [], reRegItems,
                                 roleSetter, stateSetter, reRegSetter, roleOnClick, stateOnClick, reregOnClick, onClickFirstReg,
                               isReRegChecked, isFirstTimeChecked}) {
    let id = "filter-" + index;
        return (
            <div id={id} className="filter-content card collapse" data-bs-parent="#accordion">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <ul className="list-unstyled ms-3">
                                <div className="mb-2">
                                    <h3>{i18n('filter.on.status')}</h3>
                                    <FilterSelectState i18n={i18n} label={stateLabel} items={stateItems} setter={stateSetter} onClick={stateOnClick}></FilterSelectState>
                                    <MissingState i18n={i18n} items={stateItems}></MissingState>
                                </div>
                                <div className="mb-2">
                                    <h3>{i18n('filter.on.role')}</h3>
                                    <FilterSelectRole i18n={i18n} label={roleLabel} items={roleItems} setter={roleSetter} onClick={roleOnClick}></FilterSelectRole>
                                </div>
                                <div className="mb-2">
                                    <h3>{i18n('filter.on.rereg')}</h3>
                                    <FilterSelectReReg i18n={i18n} items={reRegItems} setter={reRegSetter} onClickReReg={reregOnClick}
                                                       onClickFirstReg={onClickFirstReg} isReRegChecked={isReRegChecked} isFirstTimeChecked={isFirstTimeChecked}></FilterSelectReReg>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
}

function FilterComponentState({i18n, index, label = i => i.name, name = i => i.id,
                             items = [], setter, onClick, useFilter}) {
    let id = "filter-" + index;
    return (
        <div id={id} className="filter-content card collapse" data-bs-parent="#accordion">
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <FilterSelectState i18n={i18n} label={label} items={items} setter={setter} onClick={onClick}></FilterSelectState>
                    </div>
                </div>
            </div>
        </div>
    )
}
