import React, {useState} from 'react';
import {Link} from '../util/react-util.js';
import PageHeader from "./PageHeader";
import '../css/main.css'
import EnrollmentPaperHeader from "./EnrollmentPaperHeader";

/**
 * Component for wrapping a page with a PageHeader, breadcrumb links and styling markup.
 */
export default function PageWrapper(props) {
    const {i18n, canvasHost, context, version, paperHeaderState} = props;
    const {name: title, course_code: label, id } = context.course;
    const [paperHeader, setPaperHeader] = paperHeaderState;
    const [paperHeaderEnabled, setPaperHeaderEnabled] = useState(false);
    const decidePaperHeader = e => {
        setPaperHeaderEnabled(! paperHeaderEnabled);
    }
    return (
        <div className="App">
            <PageHeader i18n={i18n}/>
            <div className="container layout-main">
                <div className="row">
                    <div className="col">
                        <nav aria-label="Du är här" className="breadcrumbs">
                            <ol className="breadcrumb">
                                <li className="no-print breadcrumb-item"><a href="https://studium.uu.se">Studium</a> </li>
                                <li className="no-print breadcrumb-item"><a href={`${canvasHost}/courses/${id}`}>{label}</a> </li>
                                <li className="no-print breadcrumb-item active" aria-current="page"><Link to="/">{i18n('main.header.preamble')}</Link></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid jumbotron jumbotron-fluid mt-0">
                <div className="d-flex justify-content-center">
                    <div className="jumbotron-text">
                        <h2 className="kurskod">{title || ''}</h2>
                        <h2 className="paperHeader printout-header">{! paperHeaderEnabled && paperHeader || ''}</h2>
                        <EnrollmentPaperHeader i18n={i18n}
                                               paperHeaderState={[paperHeader, setPaperHeader]}
                                               paperHeaderEnabledState={[paperHeaderEnabled, setPaperHeaderEnabled]}
                        />
                        {/*<button className="no-print button-outline button-icon icon-edit mb-3" onClick={decidePaperHeader}>{i18n('projection.paper.header')}</button>*/}
                    </div>
                </div>
            </div>
            <main className="container layout-main-container">

                { props.children }
            </main>
            <footer className="layout-footer no-print">
                <div className="layout-footer-bg-img"></div>
                <div className="container">
                    <div className="row layout-footer-last-row">
                        <div className="col-12">
                            <article className="footer-site-information"
                                     aria-label="Kontakt- och organisationsinformation för Uppsala universitet">
                                <address>
                                    <span className="footer-site-entry">© Uppsala universitet</span>
                                    <span className="footer-site-entry">Telefon:&nbsp;
                                        <span className="tel">018-471 00 00</span>
                                    </span>
                                    <span className="footer-site-entry">Box 256, 751 05 Uppsala</span>
                                </address>
                                <p>
                                    <span className="footer-site-entry">Organisationsnummer:&nbsp;202100-2932</span>
                                    <span className="footer-site-entry">Momsregistreringsnummer:&nbsp;SE202100293201</span>
                                    <span className="footer-site-entry">PIC:&nbsp;999985029</span>
                                    <span className="footer-site-entry">
                                        <a href="http://www.uu.se/om-uu/kontakta-oss/registrator">Registrator</a>
                                    </span>
                                    <span className="footer-site-entry">
                                        <a href="http://www.uu.se/om-webbplatsen">Om webbplatsen</a>
                                    </span>
                                    <span className="footer-site-entry">
                                        <a href="https://www.uu.se/om-uu/dataskyddspolicy/">Dataskyddspolicy</a>
                                    </span>
                                </p>
                                {/*<p>
                                <span className="footer-site-entry">Sidansvarig:&nbsp;<a
                                    href="mailto:xxxx@uu.se">xxxx@uu.se</a></span>
                            </p>*/}
                            </article>
                            <p><span className="version text-muted">Version {version}</span></p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
