import React from "react";

/**
 * Component for adding an extra header on the page/document
 */
export default function EnrollmentPaperHeader({i18n, paperHeaderState, paperHeaderEnabledState}) {
    const [paperHeaderEnabled, setPaperHeaderEnabled] = paperHeaderEnabledState;
    const [paperHeader, setPaperHeader] = paperHeaderState;
    const changePaperHeader = e => {setPaperHeader(e.target.value);}
    const done = e => {e.preventDefault(); setPaperHeaderEnabled(!paperHeaderEnabled);}
    return (
        <div className="row no-print">
            <form className="col">
                {/*paperHeaderEnabled &&*/}
                <div className="form-group">
                    <label htmlFor="header-input">{i18n('projection.extra.paperHeader')}</label>
                    <input autoComplete="off" id="header-input" className="form-control" name="aa" value={paperHeader} onChange={changePaperHeader} />
                    {/*<button onClick={done}>{i18n('projection.extra.headers.done')}</button>*/}
                </div>
            </form>
        </div>
    );
}