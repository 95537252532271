import React, {useState} from 'react';

/**
 * Components and utility functions for the projections (headers) of the EnrollmentTable.
 */

/**
 * Initial configuration of the headers of EnrollmentTable: Labels, type, properties and if they should be enabled.
 */
export function initialHeaders(i18n) {
    return [
        {prop: 'user_checkbox',      label: i18n('table.header.select_all'),       type: 'checkbox',   enabled: true},
        {prop: 'user_sortable_name', label: i18n('table.header.user_name'),        type: 'enrollment', enabled: true},
        {prop: 'user_login_id',      label: i18n('table.header.user_login_id'),    type: 'enrollment', enabled: false},
        {prop: 'akkaId',             label: i18n('table.header.akkaId'),           type: 'enrollment', enabled: false},
        {prop: 'user_email',         label: i18n('table.header.email'),            type: 'enrollment', enabled: false},
        {prop: 'sis_user_id',        label: i18n('table.header.sis_user_id'),      type: 'enrollment', enabled: false},
        {prop: 'course_section_id',  label: i18n('table.header.sis_section_id'),   type: 'section',    enabled: false},
        {prop: 'role',               label: i18n('table.header.role'),             type: 'role',       enabled: false},
        {prop: 'group',              label: i18n('table.header.group'),            type: 'group',      enabled: false},
        {prop: 'enrollment_state',   label: i18n('table.header.enrollment_state'), type: 'enrollment', enabled: false},
        {prop: 'reregistered',       label: i18n('table.header.reregistered'),     type: 'enrollment', enabled: false},
        {prop: 'program',   label: i18n('table.header.program'),          type: 'enrollment', enabled: false}
    ];
}

/**
 * Component for configuring what to show (project) in EnrollmentTable:
 * headerState = the headers / columns to use.
 */
export function EnrollmentProjection({i18n, headerState}) {
    const [headers, setHeaders] = headerState;
    const selectHeader = h => e => setHeaders(headers.map(hd =>
        (hd.prop !== h.prop) ? hd : Object.assign({}, h, {enabled: !h.enabled})));
    return (
            <form className="col-md-12 mb-3">
                {headers.map((h, index) =>
                    index > 0 &&
                    <label key={index}>
                        <input type='checkbox'
                               name={h.prop}
                               checked={h.enabled}
                               onChange={selectHeader(h)} />
                        {h.label} &nbsp;
                    </label>
                )}
            </form>
    );
}

/**
 * Component for configuring extra headers for the EnrollmentTable.
 */
export function EnrollmentExtraHeaders({i18n, context, extraHeadersState, extraHeadersEnabledState}) {
    const [extraHeadersEnabled, setExtraHeadersEnabled] = extraHeadersEnabledState;
    const [extraHeaders, setExtraHeaders] = extraHeadersState;
    const changeHeader = h => e => setExtraHeaders(extraHeaders.map(eh =>
        (eh.prop !== h.prop) ? eh : Object.assign({}, h, {label: e.target.value})));
    const removeHeader = h => e => {e.preventDefault(); setExtraHeaders(extraHeaders.filter(eh => eh.prop !== h.prop))}
    const addHeader = e => {e.preventDefault(); setExtraHeaders([...extraHeaders, {
        label: 'Kolumn ' + (extraHeaders.length + 1), type: 'extra', prop: extraHeaders.length+1}])};
    const done = e => {e.preventDefault(); setExtraHeadersEnabled(!extraHeadersEnabled);}

    const [extraHeadersVisible, setExtraHeadersVisible] = useState([false]);;
    {/*
    const hideExtraHeaders = e => {e.preventDefault(); setExtraHeadersVisible(! extraHeadersVisible);}
     */}
    return (
        <span className="no-print float-end">
            <button className="button-outline button-icon icon-add" onClick={addHeader}>{i18n('projection.extra.headers.add')}</button>
        </span>
    );
}


