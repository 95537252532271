import React, {useEffect, useState} from 'react';
import reactUtil from './util/react-util.js';
import messages from './i18n/messages.js';
import {MessageError, MessageLoading} from "./components/Messages";
import PageWrapper from "./components/PageWrapper";
import EnrollmentView from "./components/EnrollmentView";

export default function App() {
    const i18n = reactUtil.useMessages(messages, "sv");
    const repo = reactUtil.useXhr();
    const [canvasHost, setCanvasHost] = useState();
    const [context, setContext] = useState();
    const [paperHeader, setPaperHeader] = useState([]);
    const [participations, setParticipations] = useState();
    const [userEmail, setUserEmail] = useState()
    const [version, setVersion] = useState()

    function init() {
        repo.get('/enrollment/viewModel', {}, function(r) {
            i18n({messages: messages, locale: r.locale});
            setCanvasHost(r.canvasHost);
            setContext(r.context);
            setParticipations(r.participations);
            setPaperHeader('');
            setUserEmail(r.userEmail);
            setVersion(r.version);
            const {name: title='', course_code: label=''} = r.context.course;
            const preamble = `${i18n('main.header.preamble')}${i18n('main.header.preposition')}`;
            window.document.title = `${preamble} ${label} ${title}`;
        });
    }
    useEffect(init, []);
		    
    return (
        <>
            { repo.waiting && <MessageLoading>{i18n('common.loading')}</MessageLoading> }
            { repo.error && <MessageError>{i18n('common.error')}</MessageError> }
            { context && participations &&
              <PageWrapper i18n={i18n} context={context} canvasHost={canvasHost} version={version} paperHeaderState={[paperHeader, setPaperHeader]}>
                  <EnrollmentView i18n={i18n} context={context} userEmail={userEmail} participations={participations} />
              </PageWrapper>
            }
        </>
    );
}
