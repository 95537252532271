import React from 'react';

/**
 * Components for printing and exporting (to CSV) the EnrollmentTable.
 */
export default function EnrollmentPrintAndExport({i18n, tableName, headers, rows}) {
    //Remove the first column
    headers = headers.slice(1);
    const exportCsv = encodeURIComponent(createCsv(headers, rows));
    const exportHref = `data:text/csv;charset=utf-8,\uFEFF${exportCsv}`;
    return (
        <span className="no-print float-end">
            <button type="button" className="button-outline" onClick={e => window.print()}>
                {i18n('table.print')}
            </button>
            <button type="button" className="button-outline" onClick={e => {
                        let a = document.createElement('a');
                        a.setAttribute('href', exportHref);
                        a.setAttribute('download', `${tableName}.csv`);
                        a.click();
                        a.remove();}}>
                {i18n('table.csv.export')}
            </button>
        </span>
    );
}

/**
 * Convert headers and rows to a CSV string.
 */
function createCsv(headers, rows, separator=",") {
    let csvRows = [];
    csvRows.push(headers.map(header => escapeCsv(header)).join(separator));
    rows.forEach(row => {
        if (!row.map) {
            console.log("No map: " + JSON.stringify(row));
        } else {
            //Remove the first column
            let row2 = row.slice(1);
            csvRows.push(row2.map(value => escapeCsv(value)).join(separator));

        }
    })
    return csvRows.join("\n");
}

/**
 * Escape double-quote with double-double-quote:
 * https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv
 */
function escapeCsv(value) {
    return ('"' + (value + '').replace(/"/g, '""') + '"');
}
