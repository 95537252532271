import React, {useState} from "react";
import EnrollmentFilter from "./EnrollmentFilter";
import {initialHeaders} from "./EnrollmentProjection";
import EnrollmentTable from "./EnrollmentTable";


/**
 * Main enrollment view.
 *
 * Owns the state used for configuring the data used in EnrollmentTable:
 *     headers: Specifies the columns used in EnrollmentTable. Configured in EnrollmentProjection.
 *     filters: Specifies how to filter the enrollments in EnrollmentTable. Configured in EnrollmentFilter.
 */
export default function EnrollmentView({i18n, context, participations, userEmail}) {
    const {course: {name: title='', course_code: label='', id }, sections, programs, groupCategories, groups} = context;
    const tableName = `${i18n('main.header.preamble')}${i18n('main.header.preposition')} ${label} ${title}`;
    const {enrollments, groupMembers, memberGroups} = participations;
    const roles = [ ...(new Set(enrollments.map(e => e.role)))];
    const enrollmentStates = enrollments.length == 0 ? [] : [ ...(new Set(enrollments.map(e => e.enrollment_state)))];
    const reregistered = [ ...(new Set(enrollments.map(e => e.reregistered ? e.reregistered : null).filter(e => e != null)))];
    const [headers, setHeaders] = useState(initialHeaders(i18n));
    const [extraHeadersEnabled, setExtraHeadersEnabled] = useState(false);
    const [extraHeaders, setExtraHeaders] = useState([]);
    const [paperHeaderEnabled, setPaperHeaderEnabled] = useState(false);
    const [filters, setFilters] = useState({sections: null, groups: null, programs: null});

    const [search, setSearch] = React.useState('');
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    return (
        <>
            <EnrollmentFilter i18n={i18n} context={context} roles={roles} reregistered={reregistered}
                              enrollmentStates={enrollmentStates} filterState={[filters, setFilters]} handleSearch={handleSearch}/>
            <EnrollmentTable i18n={i18n} context={context} tableName={tableName}
                             filters={filters} headers={headers} headerState={[headers, setHeaders]}
                             extraHeadersEnabledState={[extraHeadersEnabled, setExtraHeadersEnabled]}
                             extraHeadersState={[extraHeaders, setExtraHeaders]}
                             paperHeaderEnabledState={[paperHeaderEnabled, setPaperHeaderEnabled]}
                             participations = {{enrollments, groupMembers, memberGroups}}
                             userEmail={userEmail} search={search}/>
        </>
    );
}
