import React, {useState} from 'react';
/**
 * Components for printing and exporting (to CSV) the EnrollmentTable.
 */
export default function EnrollmentEmail({i18n, context, rows, isChecked, userEmail, headers}) {

    const [showModal, setShowModal] = useState(false);

    let emailAddresses = '';
    let visibleCheckBoxHeader = headers.some(function(header) {
        return header.prop === 'user_checkbox';
    });
    if (visibleCheckBoxHeader && isChecked.length > 0) {
        const emailAddressesArray = rows.filter(row => isChecked.includes(row[0].enrollment.user_id)).map(row => row[0].enrollment.user_email);
        emailAddresses = emailAddressesArray.join(';');
    }

    const Modal = ({ i18n, setShowModal, show, emailAddresses}) => {
        const showHideClassName = show ? "modal display-block" : "modal display-none";

        const copyToClipboard = str => {
            let textArea = document.createElement('textarea');
            textArea.value = str;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
        };
        return (
            <div className={showHideClassName}>
                <section className="modal-main">
                    <div className="form-group">
                        <h3>{i18n('email-failed-dialog.header')}</h3>
                        <span>{i18n('email-failed-dialog.row1')}</span>
                        <br/>
                        <span>{i18n('email-failed-dialog.row2')}</span>
                        <br/>
                        <span>{i18n('email-failed-dialog.row3')}</span>
                        <br/><br/>
                        <div className="float-start">
                            <button className="button-outline" onClick={e => copyToClipboard(emailAddresses)}>{i18n('email-failed-dialog.copy-email')}</button>
                        </div>
                        <div className="float-end">
                            <button className="button-outline" onClick={e => setShowModal(false) }>OK</button>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    const onClick = e => {

        let a = document.createElement('a');
        let subject = context.course.name;
        let body = '%0A%0A%0A' + context.course.name;
        a.setAttribute('href', 'mailto:' + userEmail + '?bcc=' + emailAddresses + '&subject=' + subject + '&body=' + body);
        a.onclick = function() {
            let t;
            window.onblur = function() {
                // The browser apparently responded, so stop the timeout.
                clearTimeout(t);
            };
            t = setTimeout(function() {
                // The browser did not respond after 1000ms, open a dialog
                setShowModal(true);
            }, 1000);
        };
        a.click();
        a.remove();
    }

    return (
        <span className="no-print float-end">
            <Modal i18n={i18n} show={showModal} setShowModal={setShowModal} emailAddresses={emailAddresses}>
                <p>Modal</p>
            </Modal>
            <button className="button-outline" aria-disabled={emailAddresses.length === 0} disabled={emailAddresses.length === 0} type="button" onClick={onClick}>{i18n('table.email')}</button>
        </span>
    );
}
